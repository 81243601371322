<template> 
    <ion-content class="bindingScrollContent" :scroll-events="true" :fullscreen="true">
    <div class="item-details">
        <ion-header class="ion-no-border bb header-details" >
          <ion-toolbar color="transparent">
            <ion-buttons slot="start" style="margin: 0">
              <ion-button @click="exit">
                <ion-icon slot="icon-only" :icon="chevronBackOutline"></ion-icon>
              </ion-button>
            </ion-buttons>
            <h5 style="padding-left: 0.5rem">Deshabilitar días</h5>
          </ion-toolbar>
        </ion-header>

        <section class="mb-md mt-md" v-if="daysBlocked && daysBlocked.length">
          <h5>Días deshabilitados</h5>
          <ion-list>
            <ion-item v-for="(day, i) in daysBlocked" :key="day">
              <ion-label>{{ showDate(day) }}</ion-label>
              <ion-buttons slot="end">
                <ion-button @click="unlockDay(i)">
                  <ion-icon slot="icon-only" color="danger" :icon="closeCircleOutline"></ion-icon>
                </ion-button>
              </ion-buttons>
            </ion-item>
          </ion-list>
        </section>

        <section id="reservar" class="mb-md mt-md">
          <h5 class="pb-xs">Deshabilitar día</h5>
            <form style="max-width: 720px; margin: 0 auto">
              <ion-grid>
                  <ion-row>
                    <ion-col size="12" class="box-input" :class="{ error: v$.reservation.fecha.$errors.length }">
                      <ion-label>Escoge una Fecha*:</ion-label>
                      <Calendar id="fecha" class="input" autocomplete="off" :disabledDates="daysBlocked" locale="es_ES" :manualInput="false" placeholder="Fecha de la reserva" v-model.lazy="v$.reservation.fecha.$model" dateFormat="dd-mm-yy" />
                      <!-- error message -->
                      <div class="input-errors" v-for="(error, index) of v$.reservation.fecha.$errors" :key="index">
                        <h6 class="error-msg">{{ error.$message }}</h6>
                      </div>
                    </ion-col>
                  </ion-row>
              </ion-grid>

              <ion-button style="margin-top: 2rem" @click="save" expand="full" shape="round" color="primary" :disabled="v$.reservation.$invalid">
                <ion-label>Deshabilitar reservas</ion-label>
              </ion-button>
            </form>
          </section>
    </div>
</ion-content>
</template>


<script>
import { IonContent, IonCheckbox, IonButton, IonLabel, IonItem, IonGrid, IonRow, IonCol, IonHeader, IonToolbar, IonButtons, modalController, IonIcon, IonList } from '@ionic/vue';
import { chevronBackOutline, closeCircleOutline } from 'ionicons/icons';

import Calendar from 'primevue/calendar';

import { defineComponent } from 'vue';

import useVuelidate from '@vuelidate/core';
import { required, email, minLength, maxLength } from '@/utils/i18n-validators';
import { mapActions, mapState } from 'vuex';

import { format } from 'date-fns'

import moment from 'moment';

export default defineComponent({
  name: 'BlockDaysPage',
  components: {
    IonContent,
    Calendar,
    IonButton,
    IonLabel,
    IonItem,
    IonGrid,
    IonRow,
    IonCol,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonIcon,
    IonList
  },
    data() {
    return {

      reservation: {
        fecha: '',
      },

    }
  },
  computed:{
      ...mapState('bookings', {
        daysBlocked: state => state.daysBlocked,
      }),
  },
  validations() {
return {
      reservation: {
        fecha: { required },    
      },
    }
  },
  methods: {
    ...mapActions('bookings',['getDaysBlocked', 'updateDaysBlocked']),

    exit(){
      modalController.dismiss();
    },

    // Fechas
    minDate(){
      const mDate = moment().add(1, 'days');
      return new Date(mDate.toDate().getTime());
    },

    unlockDay(i){
      const newDaysBlocked = JSON.parse(JSON.stringify(this.daysBlocked));
      newDaysBlocked.splice(i, 1);
      this.updateDaysBlocked(newDaysBlocked);
    },

    showDate(date){
      return format(date, "DD/MM/YYYY")
    },

    save(){
      const newDaysBlocked = JSON.parse(JSON.stringify(this.daysBlocked));

      newDaysBlocked.push(this.reservation.fecha.getTime());
      this.updateDaysBlocked(newDaysBlocked);

      this.$toast.add({severity: 'success', summary: 'Reserva Confirmada', detail: 'Se han guardado los cambios', group: 'tr', life: 5000});
      
      modalController.dismiss();
    }
  },
  created(){
    this.getDaysBlocked();
  },
  setup() {
    return { v$: useVuelidate(), chevronBackOutline, closeCircleOutline };
  }
});
</script>

<style scoped>
  .header-aside-container{
      max-width: 96rem;
      margin: 0 auto;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
  }

  .header-details{
    height: 5rem;
    padding: 1rem 0;
  }

  .zonas{
    width: 100%;
  }

  .zonas .zona{
    width: 140px;
    height: 140px;
    object-fit: cover;
    object-position: center;
    display: block;
  }

</style>