<template> 
    <ion-content class="bindingScrollContent" :scroll-events="true" :fullscreen="true">
    <div class="item-details">
        <ion-header class="ion-no-border bb header-details" >
          <ion-toolbar color="transparent">
            <ion-buttons slot="start" style="margin: 0">
              <ion-button @click="exit">
                <ion-icon slot="icon-only" :icon="chevronBackOutline"></ion-icon>
              </ion-button>
            </ion-buttons>
            <h5 style="padding-left: 0.5rem">Nueva Reserva</h5>
          </ion-toolbar>
        </ion-header>

        <section id="reservar" class="mb-md mt-md">
            <form style="max-width: 720px; margin: 0 auto">
              <ion-grid>
                  <ion-row>
                    <ion-col size="12" class="box-input" :class="{ error: v$.reservation.fecha.$errors.length }">
                      <ion-label>Escoge una Fecha*:</ion-label>
                      <Calendar id="fecha" class="input" autocomplete="off" :disabledDates="daysBlocked" :minDate="minDate()" locale="es_ES" :manualInput="false" @date-select="checkAvailability" placeholder="Fecha de la reserva" v-model.lazy="v$.reservation.fecha.$model" dateFormat="dd-mm-yy" />
                      <!-- error message -->
                      <div class="input-errors" v-for="(error, index) of v$.reservation.fecha.$errors" :key="index">
                        <h6 class="error-msg">{{ error.$message }}</h6>
                      </div>
                    </ion-col>

                    <ion-col size="12" size-lg="6"  class="box-input" :class="{ error: v$.reservationType.$errors.length }">
                      <ion-label>Selecciona un Horario:</ion-label>
                      <SelectButton v-model.lazy="v$.reservationType.$model" :options="typeOptions" @change="checkAvailability" optionLabel="name"  optionValue="value"/>
                      <!-- error message -->
                      <div class="input-errors" v-for="(error, index) of v$.reservationType.$errors" :key="index">
                        <h6 class="error-msg">{{ error.$message }}</h6>
                      </div>
                    </ion-col>

                    <ion-col size="12" size-lg="6" class="box-input" :class="{ error: v$.reservation.hora.$errors.length }">
                      <ion-label>Escoge una Hora*:</ion-label>
                      <Dropdown v-if="reservationType === 'almuerzo'" @change="checkAvailability" v-model.trim.lazy="v$.reservation.hora.$model" class="input" :options="dayHours" optionLabel="name" optionValue="value" placeholder="Hora de la Reserva" />
                      <Dropdown v-if="reservationType === 'cena' && !isWeekend()" @change="checkAvailability" v-model.trim.lazy="v$.reservation.hora.$model" class="input" :options="nightHours" optionLabel="name" optionValue="value" placeholder="Hora de la Reserva" />
                      <Dropdown v-if="reservationType === 'cena' && isWeekend()" @change="checkAvailability" v-model.trim.lazy="v$.reservation.hora.$model" class="input" :options="nightHoursWeekend" optionLabel="name" optionValue="value" placeholder="Hora de la Reserva" />


                      <!-- error message -->
                      <div class="input-errors" v-for="(error, index) of v$.reservation.hora.$errors" :key="index">
                        <h6 class="error-msg">{{ error.$message }}</h6>
                      </div>
                    </ion-col>

                    <ion-col size="12"  class="box-input" :class="{ error: v$.reservation.persons.$errors.length }">
                      <ion-label>Número de Personas*:</ion-label>
                      <Dropdown @change="checkAvailability" v-model.lazy="v$.reservation.persons.$model" class="input" :options="personsOptions" optionLabel="name" optionValue="value" placeholder="Numero de personas" />
                      <!-- error message -->
                      <div class="input-errors" v-for="(error, index) of v$.reservation.persons.$errors" :key="index">
                        <h6 class="error-msg">{{ error.$message }}</h6>
                      </div>
                    </ion-col>

                    <ion-col size="12" class="box-input" :class="{ error: v$.reservation.zone.$errors.length }">
                      <ion-label>Selecciona una Zona:</ion-label>
                      <SelectButton v-model.lazy="v$.reservation.zone.$model" :options="zoneOptions" optionDisabled="disabled" optionLabel="name" class="zonas"  optionValue="value">
                        <template #option="slotProps">
                          <div class="display: block">
                            <img :src="slotProps.option.image" class="zona"/>
                            <p class="pt-xxs">{{ slotProps.option.name }}</p>
                          </div>
                        </template>
                      </SelectButton>
                      <!-- error message -->
                      <div class="input-errors" v-for="(error, index) of v$.reservation.zone.$errors" :key="index">
                        <h6 class="error-msg">{{ error.$message }}</h6>
                      </div>
                    </ion-col>

                    <p class="text-danger" v-if="reservation.persons > 4 && reservation.zone && reservation.zone !== 'Jaulas'">*La reserva será dividida en varias mesas.</p>
                    <p v-if="reservation.persons <= 4">*Para reservar una una mesa en las Jaulas es necesario un mínimo de 5 personas.</p>
                  </ion-row>

                    <h4 class="pt-xs pb-xxs">Datos de la Reserva</h4>

                  <ion-row>
                      <ion-col size="12" size-lg="6" class="box-input" :class="{ error: v$.reservation.customer.firstName.$errors.length }">
                      <ion-label>Nombre*:</ion-label>
                      <InputText class="input secondary-input" id="firstName" placeholder="Nombre*" type="text" v-model.trim.lazy="v$.reservation.customer.firstName.$model" />
                      <!-- error message -->
                      <div class="input-errors" v-for="(error, index) of v$.reservation.customer.firstName.$errors" :key="index">
                        <h6 class="error-msg">{{ error.$message }}</h6>
                      </div>
                    </ion-col>
                    <ion-col size="12" size-lg="6" class="box-input" :class="{ error: v$.reservation.customer.lastName.$errors.length }">
                      <ion-label>Apellidos*:</ion-label>
                      <InputText class="input" id="lastName" placeholder="Apellidos*" type="text" v-model.trim.lazy="v$.reservation.customer.lastName.$model" />
                      <!-- error message -->
                      <div class="input-errors" v-for="(error, index) of v$.reservation.customer.lastName.$errors" :key="index">
                        <h6 class="error-msg">{{ error.$message }}</h6>
                      </div>
                    </ion-col>
                    <ion-col size="12" size-lg="6" class="box-input" :class="{ error: v$.reservation.customer.phone.$errors.length }">
                      <ion-label>Teléfono*:</ion-label>
                      <InputText class="input secondary-input" id="phone" placeholder="Teléfono*" type="tel" minLength="9" maxLength="9" v-model.trim.lazy="v$.reservation.customer.phone.$model" />
                      <!-- error message -->
                      <div class="input-errors" v-for="(error, index) of v$.reservation.customer.phone.$errors" :key="index">
                        <h6 class="error-msg">{{ error.$message }}</h6>
                      </div>
                    </ion-col>
                    <ion-col size="12" size-lg="6" class="box-input" :class="{ error: v$.reservation.customer.email.$errors.length }">
                      <ion-label>Email*:</ion-label>
                      <InputText class="input" id="email" placeholder="Email" type="email" v-model.trim.lazy="v$.reservation.customer.email.$model" />
                      <!-- error message -->
                      <div class="input-errors" v-for="(error, index) of v$.reservation.customer.email.$errors" :key="index">
                        <h6 class="error-msg">{{ error.$message }}</h6>
                      </div>
                    </ion-col>
                    <ion-col size="12" class="box-input" :class="{ error: v$.reservation.observations.$errors.length }">
                      <ion-label>Observaciones:</ion-label>
                      <Textarea  v-model.trim.lazy="v$.reservation.observations.$model" placeholder="Observaciones" :autoResize="true" rows="3"/>
                      <!-- error message -->
                      <div class="input-errors" v-for="(error, index) of v$.reservation.observations.$errors" :key="index">
                        <h6 class="error-msg">{{ error.$message }}</h6>
                      </div>
                    </ion-col>
                  </ion-row>

                  <ion-row>
                    <ion-col size="12" class="box-input">
                      <ion-item lines="none">
                        <ion-checkbox slot="start" color="primary" v-model.lazy="reservation.customer.privacy" name="privacy"></ion-checkbox>
                        <ion-label text-wrap>El usuario acepta la <a href="https://biokorestaurante.com/privacidad" target="_blank">Política de Privacidad</a> y consiento¡e en el tratamiento de sus datos personales.*</ion-label>
                      </ion-item>
                    </ion-col>
                    <ion-col size="12" class="box-input">
                      <ion-item lines="none">
                        <ion-checkbox slot="start" color="primary" v-model.lazy="reservation.customer.commercial" name="commercial"></ion-checkbox>
                        <ion-label text-wrap>El usuario acepta el uso de sus datos personales para el envío de Ofertas, promociones, descuentos e infomración comercial.</ion-label>
                      </ion-item>
                    </ion-col>
                  </ion-row>

                  <h6 class="text-center">Los campos marcados con (*) son obligatorios.</h6>
              </ion-grid>

              <ion-button style="margin-top: 2rem" @click="save" expand="full" shape="round" color="primary" :disabled="v$.reservation.$invalid || !reservation.customer.privacy">
                <ion-label>Reservar</ion-label>
              </ion-button>
            </form>
          </section>
    </div>
</ion-content>
</template>


<script>
import { IonContent, IonCheckbox, IonButton, IonLabel, IonItem, IonGrid, IonRow, IonCol, IonHeader, IonToolbar, IonButtons, modalController } from '@ionic/vue';
import { chevronBackOutline } from 'ionicons/icons';

import SelectButton from 'primevue/selectbutton';
import Calendar from 'primevue/calendar';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';

import { defineComponent } from 'vue';

import useVuelidate from '@vuelidate/core';
import { required, email, minLength, maxLength } from '@/utils/i18n-validators';
import { mapActions, mapState } from 'vuex';

import moment from 'moment';

export default defineComponent({
  name: 'BookingsPage',
  components: {
    IonContent,
    SelectButton,
    Calendar,
    Dropdown,
    InputText,
    IonCheckbox,
    Textarea,
    IonButton,
    IonLabel,
    IonItem,
    IonGrid,
    IonRow,
    IonCol,
    IonHeader,
    IonToolbar,
    IonButtons
  },
    data() {
    return {
      reservationType: 'almuerzo',

      reservation: {
        reservationDate: '',
        zone: 'Salón',
        persons: 1,
        observations: '',
        fecha: '',
        hora: '',
        customer: {
          firstName: '',
          lastName: '',
          phone: '',
          email: '',
          privacy: false,
          commercial: false,
        },
      },


      zoneOptions: [
          {
           name: 'Terraza Interior',
           value: 'Terraza Interior',
           disabled: false,
           reservations: 0,
           image: 'https://firebasestorage.googleapis.com/v0/b/biokorestaurante-com.appspot.com/o/web%2Freservas%2Fthumbnails%2Freservas1_512x512.webp?alt=media'
          },
          {
           name: 'Mesas Altas',
           value: 'Mesas Altas',
           disabled: false,
           reservations: 0,
           image: 'https://firebasestorage.googleapis.com/v0/b/biokorestaurante-com.appspot.com/o/web%2Freservas%2Fthumbnails%2Freservas2_512x512.webp?alt=media'
          },
          {
           name: 'Salón',
           value: 'Salón',
           disabled: false,
           reservations: 0,
           image: 'https://firebasestorage.googleapis.com/v0/b/biokorestaurante-com.appspot.com/o/web%2Freservas%2Fthumbnails%2Freservas3_512x512.webp?alt=media'
          },
          {
           name: 'Jaulas',
           value: 'Jaulas',
           disabled: false,
           reservations: 0,
           image: 'https://firebasestorage.googleapis.com/v0/b/biokorestaurante-com.appspot.com/o/web%2Freservas%2Fthumbnails%2Freservas4_512x512.webp?alt=media'
          },
      ],

      typeOptions: [
          {name: 'Almuerzo', value: 'almuerzo'},
          {name: 'Cenar', value: 'cena'},
      ],

      dayHours: [
          {name: '13:00', value: '13:00'},
          {name: '13:30', value: '13:30'},
          {name: '14:00', value: '14:00'},
          {name: '14:30', value: '14:30'},
          {name: '15:00', value: '15:00'},
          {name: '15:30', value: '15:30'},
      ],
      nightHours: [
          {name: '20:00', value: '20:00'},
          {name: '20:30', value: '20:30'},
          {name: '21:00', value: '21:00'},
          {name: '21:30', value: '21:30'},
          {name: '22:00', value: '22:00'},
          {name: '22:30', value: '22:30'},
          {name: '23:00', value: '23:00'},
      ],
      nightHoursWeekend: [
          {name: '20:00', value: '20:00'},
          {name: '21:00', value: '21:00'},
          {name: '23:00', value: '23:00'},
      ],
      personsOptions: [
          {name: 1, value: 1},
          {name: 2, value: 2},
          {name: 3, value: 3},
          {name: 4, value: 4},
          {name: 5, value: 5},
          {name: 6, value: 6},
          {name: 7, value: 7},
          {name: 8, value: 8},
          {name: 9, value: 9},
          {name: 10, value: 10},
          {name: 11, value: 11},
          {name: 12, value: 12},
          {name: 13, value: 13},
          {name: 14, value: 14},
          {name: 15, value: 15},
          {name: 16, value: 16},
          {name: 17, value: 17},
          {name: 18, value: 18},
          {name: 19, value: 19},
          {name: 20, value: 20},
          {name: 21, value: 21},
          {name: 22, value: 22},
          {name: 23, value: 23},
          {name: 24, value: 24},
      ],
    }
  },
  computed:{
      ...mapState('bookings', {
        bookings: state => state.bookings,
        daysBlocked: state => state.daysBlocked,
      }),
  },
  validations() {
return {
      reservationType: {  },

      reservation: {
        reservationDate: {},
        zone: { required },
        persons: { required },
        observations: { },

        fecha: { required },
        hora: { required },

        customer: {
          firstName: { required },
          lastName: { required },
          phone: { required, minLength: minLength(9), maxLength: maxLength(9) },
          email: { required, email },
          privacy: { required },
          commercial: {},
        },
      },
    }
  },
  methods: {
    ...mapActions('bookings',['getBookings', 'addBooking', 'getDaysBlocked']),

    exit(){
      modalController.dismiss();
    },

    checkAvailability(){
      // Obtener Reservas
      this.zoneOptions[0].reservations = 0;
      this.zoneOptions[1].reservations = 0;
      this.zoneOptions[2].reservations = 0;
      this.zoneOptions[3].reservations = 0;

      const tiempo = this.reservation.hora.split(":");
      const hour = tiempo[0];
      const minute = tiempo[1];
      const actualDate = new Date(moment(this.reservation.fecha).set('hour', hour).set('minute', minute)).getTime();
      const maxDate = new Date(moment(moment(this.reservation.fecha).set('hour', hour).set('minute', minute)).add(30, 'minute')).getTime();
      
      const filtrados = this.bookings.filter(x => actualDate <= x.reservationDate && x.reservationDate <= maxDate);
      if(filtrados.length){
        filtrados.forEach(element => {
          switch (element.zone) {
            case 'Terraza Interior':
                this.zoneOptions[0].reservations = this.zoneOptions[0].reservations + 1;
              break;
            case 'Mesas Altas':
                this.zoneOptions[1].reservations = this.zoneOptions[1].reservations + 1;
              break;
            case 'Salón':
                this.zoneOptions[2].reservations = this.zoneOptions[2].reservations + 1;
              break;
            case 'Jaulas':
                this.zoneOptions[3].reservations = this.zoneOptions[3].reservations + 1;
              break;
          }
        });
      }

      // Desactivar Opciones
      if(this.reservation.persons <= 4){
        if(this.zoneOptions[0].reservations  >= 5){
          this.zoneOptions[0].disabled = true;
        } else {
          this.zoneOptions[0].disabled = false;
        }

        if(this.zoneOptions[1].reservations  >= 3){
          this.zoneOptions[1].disabled = true;
        } else {
          this.zoneOptions[1].disabled = false;
        }

        if(this.zoneOptions[2].reservations >= 6){
          this.zoneOptions[2].disabled = true;
        } else {
          this.zoneOptions[2].disabled = false;
        }

        // Desactivar Jaulas
        this.zoneOptions[3].disabled = true;

      } else if(this.reservation.persons > 4) {
        if(this.zoneOptions[0].reservations  >= 5){
          this.zoneOptions[0].disabled = true;
        } else {
          this.zoneOptions[0].disabled = false;
        }

        if(this.zoneOptions[1].reservations  >= 3){
          this.zoneOptions[1].disabled = true;
        } else {
          this.zoneOptions[1].disabled = false;
        }

        if(this.zoneOptions[2].reservations >= 6){
          this.zoneOptions[2].disabled = true;
        } else {
          this.zoneOptions[2].disabled = false;
        }

        if(this.zoneOptions[3].reservations >= 3){
          this.zoneOptions[3].disabled = true;
        } else {
          this.zoneOptions[3].disabled = false;
        }
      }
    },

    // Fechas
    minDate(){
      const mDate = moment().add(1, 'days');
      return new Date(mDate.toDate().getTime());
    },

    isWeekend(){
      if(this.reservation.fecha){
        const dayOfWeek = this.reservation.fecha.getDay();
        return (dayOfWeek === 6) || (dayOfWeek  === 5);
      } else {
        return false;
      }
    },

    save(){
      const newReservation = JSON.parse(JSON.stringify(this.reservation));

      const tiempo = this.reservation.hora.split(":");
      const hour = tiempo[0];
      const minute = tiempo[1];
      newReservation.reservationDate = new Date(moment(this.reservation.fecha).set('hour', hour).set('minute', minute)).getTime();
      delete newReservation.hora;
      delete newReservation.fecha;

      newReservation.dateCreated = new Date().getTime();
      newReservation.status = 2;

      this.addBooking(newReservation);
      this.$toast.add({severity: 'success', summary: 'Reserva Confirmada', detail: 'Se ha guardado una nueva reserva', group: 'tr', life: 5000});
      
      modalController.dismiss();
    }
  },
  created(){
    this.getBookings();
    this.checkAvailability();
    this.getDaysBlocked();
  },
  setup() {
    return { v$: useVuelidate(), chevronBackOutline };
  }
});
</script>

<style scoped>
  .header-aside-container{
      max-width: 96rem;
      margin: 0 auto;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
  }

  .header-details{
    height: 5rem;
    padding: 1rem 0;
  }

  .zonas{
    width: 100%;
  }

  .zonas .zona{
    width: 140px;
    height: 140px;
    object-fit: cover;
    object-position: center;
    display: block;
  }

</style>